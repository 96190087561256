import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

// bootstrap reactjs
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

// custom components
import Input from "../../../../components/UI/Input/Input";

// styling
import { customStyle } from "../../../../hoc/CustomStyle";
import { checkValidity } from "../../../../hoc/Util";
import { s2_endorse } from "../../../../hoc/FormConfig";

class EndorsePage extends Component {
  state = {
    render: {
      mode: "loaded", //loading
    },

    controls: null,

    internalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    spinner: {
      toggle: false,
      msg: "",
    },

    externalMsg: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  toggleSpinner(trigger, message) {
    this.setState({
      ...this.state,
      spinner: {
        toggle: trigger,
        msg: message,
      },
    });
  }

  messageHandler(msg_type, msg_content) {
    if (msg_type === "expired") {
      this.setState({
        ...this.state,
        externalMsg: {
          triggered: true,
          type: "error",
          content: msg_content,
        },
      });
    } else {
      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: msg_type,
          content: msg_content,
        },
      });
    }
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      controls: s2_endorse,
    });
  }

  uploadInputHandler = (event, controlName) => {
    let validateOutput = true;
    let beingTouched = false;
    if (event.target.files[0]) {
      validateOutput = checkValidity(
        event.target.files[0].size,
        this.state.controls[controlName].validation
      );
      beingTouched = true;
    } else {
      beingTouched = false;
    }

    const updatedStates = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: "",
        selectedFile: event.target.files[0],
        valid: validateOutput.isValid,
        touched: beingTouched,
        elementDecorators: {
          ...this.state.controls[controlName].elementDecorators,
          feedbackMsg: validateOutput.message,
        },
      },
    };
    this.setState({ controls: updatedStates });
  };

  inputChangedHandler = (event, controlName) => {
    let validateOutput = checkValidity(
      event.target.value,
      this.state.controls[controlName].validation
    );

    let updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: validateOutput.isValid,
        elementDecorators: {
          ...this.state.controls[controlName].elementDecorators,
          feedbackMsg: validateOutput.message,
        },
        touched: true,
      },
    };

    this.setState({ controls: updatedControls });
  };

  submitHandler = (event) => {
    event.preventDefault();

    this.toggleSpinner(true, "null");

    let validated = true;
    Object.entries(this.state.controls).forEach(([formKey, formEntry], idx) => {
      if (validated && !formEntry.valid) {
        validated = false;
        //console.log(formKey + " is invalid!");
      }
    });

    let submission_type = "fullpaper";
    let route_link = "submission/endorse";

    if (validated) {
      //let collaboration_endorse = Number(this.props.quota.current_quota.collaboration);
      //let collaboration_max = Number(this.props.quota.max_quota.collaboration);
      //let joint_lab_endorse = Number(this.props.quota.current_quota.joint_lab);
      //let joint_lab_max = Number(this.props.quota.max_quota.joint_lab);
      //let total_endorse = Number(this.props.quota.current_quota.total);
      //let total_max = Number(this.props.quota.max_quota.total);
      //if (total_endorse + 1 > total_max){
      //    window.confirm("Maximum endorsement quota reached!")
      //    validated = false;
      //}
      //else{
      //    // Won't reach here.
      //    //if (this.props.endorse_type === 'Collaboration'){
      //    //    if (collaboration_endorse + 1 > collaboration_max){
      //    //        window.confirm("Collaboration endorsement quota reached!")
      //    //        validated = false
      //    //    }
      //    //}else{
      //    //    if (joint_lab_endorse + 1 > joint_lab_max){
      //    //        window.confirm("Joint Lab endorsement quota reached!")
      //    //        validated = false
      //    //    }
      //    //}
      //}
    }

    if (validated) {
      this.setState({
        ...this.state,
        render: { mode: "loading" },
      });

      let accessToken = localStorage.getItem("access_token");
      if (accessToken) {
        let fullFormData = new FormData();
        for (let key in this.state.controls) {
          if (this.state.controls[key].elementType === "file") {
            fullFormData.append(key, this.state.controls[key].selectedFile);
          } else {
            fullFormData.set(key, this.state.controls[key].value);
          }
        }

        fullFormData.set("submission_id", this.props.submission_id);
        fullFormData.set("submission_type", submission_type);

        axios({
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          url: process.env.REACT_APP_AXIOS_URL + route_link,
          method: "post",
          auth: {
            username: accessToken,
            password: "unused",
          },
          data: fullFormData,
        })
          .then((received) => {
            this.toggleSpinner(false, "null");

            if (received.data) {
              if (received.data.status) {
                this.props.formHandler();
              } else {
                this.setState({
                  ...this.state,
                  render: { mode: "loaded" },
                  internalMsg: {
                    triggered: true,
                    type: "error",
                    content: received.data.message,
                  },
                });
              }
            } else {
              this.setState({
                ...this.state,
                render: { mode: "loaded" },
                internalMsg: {
                  triggered: true,
                  type: "error",
                  content: "something is wrong",
                },
              });
            }
          })
          .catch((error) => {
            this.toggleSpinner(false, "null");

            if (error.response && error.response.status === 401) {
              this.messageHandler(
                "expired",
                "Session expired. Please log in again"
              );
            } else {
              this.props.formHandler();
            }
          });
      }

      this.setState({ ...this.state, render: { mode: "loading" } });
    } else {
      this.toggleSpinner(false, "null");
    }
  };

  render() {
    // message handling
    let msg = null;
    if (this.state.internalMsg.triggered) {
      if (this.state.internalMsg.type === "success") {
        msg = (
          <p style={customStyle.successMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      } else if (this.state.internalMsg.type === "error") {
        msg = (
          <p style={customStyle.errorMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      }
    }

    // redirect to login page if there is any error
    if (
      this.state.externalMsg.triggered &&
      this.state.externalMsg.type === "error"
    ) {
      localStorage.clear("access_token");
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: {
                type: "error",
                content: this.state.externalMsg.content,
              },
            },
          }}
        />
      );
    }

    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }

    const form = formElementsArray.map((formElement) => {
      return (
        <Input
          key={formElement.id}
          label={formElement.config.label}
          value={formElement.config.value}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          elementDecorators={formElement.config.elementDecorators}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={
            formElement.config.elementType === "file"
              ? (event) => this.uploadInputHandler(event, formElement.id)
              : (event) => this.inputChangedHandler(event, formElement.id)
          }
        />
      );
    });

    let submit_btn = (
      <Button variant="success" type="submit">
        Endorse
      </Button>
    );
    let back_btn = (
      <Button variant="info" onClick={() => this.props.formHandler("back")}>
        Back
      </Button>
    );

    if (this.state.render.mode === "loading") {
      back_btn = <Button variant="info">Back</Button>;

      submit_btn = (
        <>
          <Button variant="success" disabled>
            <Spinner animation="border" size="sm" />
          </Button>
          <p>Loading, please do not refresh your page</p>
        </>
      );
    }

    return (
      <>
        {back_btn}{" "}
        <div style={customStyle.topBuffer20}>
          <Form id="" onSubmit={this.submitHandler}>
            {form}
            {submit_btn}
          </Form>
          {msg}
        </div>
      </>
    );
  }
}

export default EndorsePage;
