import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import NavigationItems from "../../components/Navigations/NavigationItems";
import Input from "../../components/UI/Input/Input";
import Aux from "../../hoc/Auxx/Auxx";
import axios from "axios";

import { checkValidity, checkMatchValidity } from "../../hoc/Util";
import { customStyle } from "../../hoc/CustomStyle";

// inBuilt bootstrap style
const controlClasses = {
  frame: {
    col: {
      span: 8,
      offset: 2,
    },
  },
};

class Register extends Component {
  state = {
    controls: {
      email: {
        elementType: "input",
        elementConfig: {
          type: "email",
          placeholder: "Email Address",
          isValid: false,
          isInvalid: false,
          required: true,
        },
        elementDecorators: {
          label: "Email",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        validation: {
          isEmail: true,
          maxLength: 200,
          required: true,
        },
        value: "",
        valid: false,
        touched: false,
      },
      confirmEmail: {
        elementType: "input",
        elementConfig: {
          type: "email",
          placeholder: "Confirm Email Address",
          isValid: false,
          isInvalid: false,
          required: true,
        },
        elementDecorators: {
          label: "Confirm Email",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        validation: {
          isEmail: true,
          maxLength: 200,
          required: true,
        },
        value: "",
        valid: false,
        touched: false,
      },

      firstname: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "First Name",
          isValid: false,
          isInvalid: false,
          required: true,
        },
        elementDecorators: {
          label: "First Name",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        validation: {
          isText: true,
          maxLength: 200,
          required: true,
        },
        value: "",
        valid: false,
        touched: false,
      },

      lastname: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "Last Name",
          isValid: false,
          isInvalid: false,
          required: true,
        },
        elementDecorators: {
          label: "Last Name",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        validation: {
          isText: true,
          maxLength: 200,
          required: true,
        },
        value: "",
        valid: false,
        touched: false,
      },

      user_type: {
        elementType: "select",
        elementConfig: {
          options: [
            {
              value: "",
              label: "<----- Please select from drop-down menu ----->",
            },
            { value: "admin-super", label: "Super Admin" },
            { value: "admin-cluster", label: "Cluster Admin" },
            //{ value: "reviewer", label: "Reviewer" }
          ],
          isValid: false,
          isInvalid: false,
        },
        elementDecorators: {
          label: "User Type",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        value: "submitter",
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
      },

      affiliation: {
        elementType: "select",
        elementConfig: {
          options: [
            {
              value: "",
              label: "<----- Please select from drop-down menu ----->",
            },
            {
              value: "a-star",
              label: "Agency for Science, Technology and Research (A*STAR)",
            },
            { value: "nus", label: "National University of Singapore (NUS)" },
            { value: "ntu", label: "Nanyang Technological University (NTU)" },
            { value: "smu", label: "Singapore Management University (SMU)" },
            {
              value: "sutd",
              label: "Singapore University of Technology and Design (SUTD)",
            },
            { value: "sit", label: "Singapore Institute of Technology (SIT)" },
            {
              value: "suss",
              label: "Singapore University of Social Sciences (SUSS)",
            },
            {
              value: "inter",
              label: "International",
            },
          ],
          isValid: false,
          isInvalid: false,
        },
        elementDecorators: {
          label: "Affiliation",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
      },

      department: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "Department / Faculty",
          isValid: false,
          isInvalid: false,
          required: true,
        },
        elementDecorators: {
          label: "Department / Faculty",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        validation: {
          isText: true,
          maxLength: 200,
          required: true,
        },
        value: "",
        valid: false,
        touched: false,
      },

      password: {
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "Password",
          isValid: false,
          isInvalid: false,
          required: true,
        },
        elementDecorators: {
          label: "Password",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        validation: {
          minLength: 6,
          maxLength: 200,
          required: true,
        },

        value: "",
        valid: false,
        touched: false,
      },
      confirmPassword: {
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "Confirm Password",
          isValid: false,
          isInvalid: false,
          required: true,
        },
        elementDecorators: {
          label: "Password",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        validation: {
          minLength: 6,
          maxLength: 200,
          required: true,
        },

        value: "",
        valid: false,
        touched: false,
      },

      agreement: {
        elementType: "checkbox",
        elementConfig: {
          required: true,
        },
        elementDecorators: {
          label:
            "By registering, I agree to follow the grant call guidelines and submission requirements of AI Singapore.",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        validation: {
          required: true,
        },

        valid: false,
        touched: false,
      },
    },

    loading: false,
    back: false,
    isAdmin: false,

    message: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  constructor(props) {
    super(props);
    if (this.props.location.state) this.state.isAdmin = true;
  }

  toggleLoading(status) {
    this.setState({ ...this.state, loading: status });
  }

  inputChangedHandler = (event, controlName) => {
    let validateOutput = null;

    if (controlName === "confirmEmail") {
      validateOutput = checkMatchValidity(
        event.target.value,
        this.state.controls["email"].value,
        "Emails"
      );
    } else if (controlName === "confirmPassword") {
      validateOutput = checkMatchValidity(
        event.target.value,
        this.state.controls["password"].value,
        "Passwords"
      );
    } else if (
      controlName === "password" &&
      this.state.controls["confirmPassword"].value.length > 0
    ) {
      validateOutput = checkMatchValidity(
        event.target.value,
        this.state.controls["confirmPassword"].value,
        "Passwords"
      );
    }
    // Seems to create a race condition with nonsensical results.
    // Governance/Research GMS does not even bother.
    //else if (
    //  controlName === "email" &&
    //  this.state.controls["confirmEmail"].value.length > 0
    //) {
    //  validateOutput = checkMatchValidity(
    //    event.target.value,
    //    this.state.controls["confirmEmail"].value,
    //    "Passwords"
    //  );
    //}
    else {
      validateOutput = checkValidity(
        event.target.value,
        this.state.controls[controlName].validation
      );
    }

    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: validateOutput.isValid,
        elementDecorators: {
          ...this.state.controls[controlName].elementDecorators,
          feedbackMsg: validateOutput.message,
        },
        touched: true,
      },
    };

    this.setState({ controls: updatedControls });
  };

  submitHandler = (event) => {
    event.preventDefault();

    let validated = true;

    if (!this.state.controls.email.valid) {
      validated = false;
      //console.log("email is invalid");
    }

    if (!this.state.controls.confirmEmail.valid) {
      validated = false;
      //console.log("confirm email is invalid");
    }

    if (!this.state.controls.password.valid) {
      validated = false;
      //console.log("password field is invalid");
    }

    if (!this.state.controls.confirmPassword.valid) {
      validated = false;
      //console.log("confirmed password field is invalid");
    }

    if (!this.state.controls.firstname.valid) {
      validated = false;
      //console.log("firstname field is invalid");
    }

    if (!this.state.controls.lastname.valid) {
      validated = false;
      //console.log("lastname field is invalid");
    }

    if (!this.state.controls.affiliation.valid) {
      validated = false;
      //console.log("affiliation field is invalid");
    }

    if (!this.state.controls.department.valid) {
      validated = false;
      //console.log("department field is invalid");
    }

    /*
        if (!this.state.controls.agreement.valid){
            validated = false;
            console.log("agreement field is invalid");
        }
        */

    if (validated) {
      this.toggleLoading(true);

      axios
        .post(process.env.REACT_APP_AXIOS_URL + "user/register", {
          email: this.state.controls.email.value,
          user_type: this.state.controls.user_type.value,
          password: this.state.controls.password.value,
          firstname: this.state.controls.firstname.value,
          lastname: this.state.controls.lastname.value,
          affiliation: this.state.controls.affiliation.value,
          department: this.state.controls.department.value,
          agreement: true,
        })
        .then((received) => {
          this.toggleLoading(false);

          let _success = received.data.status;
          let _message = received.data.message;

          if (_success) {
            this.setState({
              message: {
                triggered: true,
                type: "success",
                content:
                  "Successfully registered, please login using your credentials",
              },
            });
          } else {
            this.setState({
              message: {
                triggered: true,
                type: "error",
                content: _message,
              },
            });
          }
        })
        .catch((error) => {
          this.toggleLoading(false);
        });
    } else {
      this.toggleLoading(false);
    }
  };

  redirect() {
    this.setState({ back: true });
  }
  render() {
    if (this.state.back)
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: {
                type: "success",
                content: this.state.message.content,
              },
            },
          }}
        />
      );
    // message handling
    let msg = null;
    if (this.state.message.triggered) {
      if (this.state.message.type === "error") {
        msg = (
          <p style={customStyle.errorMessage}>{this.state.message.content}</p>
        );
      } else if (this.state.message.type === "success") {
        return (
          <Redirect
            to={{
              pathname: "/",
              state: {
                message: {
                  type: "success",
                  content: this.state.message.content,
                },
              },
            }}
          />
        );
      }
    }

    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      });

      if (key === "user_type" && !this.state.isAdmin) {
        formElementsArray.pop();
      }
    }
    let back = null;
    if (this.state.isAdmin)
      back = (
        <>
          <Button variant="info" onClick={() => this.redirect()}>
            Back
          </Button>
        </>
      );

    const form = formElementsArray.map((formElement) => (
      <Input
        key={formElement.id}
        label={formElement.config.label}
        value={formElement.config.value}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        elementDecorators={formElement.config.elementDecorators}
        invalid={!formElement.config.valid}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched}
        changed={(event) => this.inputChangedHandler(event, formElement.id)}
      />
    ));

    return (
      <Aux>
        <NavigationItems login={false} />
        <Container>
          <Row>
            <Col md={controlClasses.frame.col}>
              {msg}
              <Card style={customStyle.topBuffer30}>
                <Card.Header>
                  <b>Register an account</b>
                </Card.Header>
                <Card.Body>
                  {back}{" "}
                  <Form onSubmit={this.submitHandler}>
                    {form}
                    {this.state.loading ? (
                      <Button variant="primary" disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          role="status"
                          aria-hidden="true"
                          size="sm"
                        />
                        Registering...
                      </Button>
                    ) : (
                      <Button type="submit">Submit</Button>
                    )}
                  </Form>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <p>
                    Already have an account? Click <a href={"/"}>here</a> to
                    login.
                  </p>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      </Aux>
    );
  }
}

export default Register;
