import React from "react";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { customStyle } from "../../../hoc/CustomStyle";

const input = (props) => {
  let inputElement = null;
  let innerBlockElement = null;

  // additional classes
  const extraClasses = {};
  if (props.shouldValidate && props.touched) {
    if (props.invalid) {
      props.elementConfig.isInvalid = true;
      props.elementConfig.isValid = false;
      props.elementDecorators.feedback = "invalid";
    } else {
      props.elementConfig.isInvalid = false;
      props.elementConfig.isValid = true;
      props.elementDecorators.feedback = "valid";
    }
  }

  /*
    // Debugging
    if (props.elementType === 'select'){
        console.log({
            name: props.elementDecorators.label,
            invalid: props.invalid,
            shouldValidate: props.shouldValidate,
            touched: props.touched
        })
    }
    */

  switch (props.elementType) {
    case "input":
      inputElement = (
        <>
          <Form.Group>
            <Form.Label>
              <b>{props.elementDecorators.label}</b>
            </Form.Label>
            <Form.Control
              {...extraClasses}
              {...props.elementConfig}
              value={props.value}
              onChange={props.changed}
            />
            <Form.Control.Feedback type={props.elementDecorators.feedback}>
              {props.elementDecorators.feedbackMsg}
            </Form.Control.Feedback>
          </Form.Group>
        </>
      );
      break;
    case "input-inline":
      inputElement = (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              <b>{props.elementDecorators.label}</b>
              <p style={customStyle.subText}>
                {props.elementDecorators.sublabel}
              </p>
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                {...extraClasses}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}
                disabled={props.elementConfig.disabled}
              />
              <Form.Control.Feedback type={props.elementDecorators.feedback}>
                {props.elementDecorators.feedbackMsg}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </>
      );
      break;
    case "textarea":
      inputElement = (
        <>
          <Form.Group>
            <Form.Label>
              <b>{props.elementDecorators.label}</b>
            </Form.Label>
            <Form.Control
              as="textarea"
              {...extraClasses}
              {...props.elementConfig}
              value={props.value}
              onChange={props.changed}
            />
            <Form.Control.Feedback type={props.elementDecorators.feedback}>
              {props.elementDecorators.feedbackMsg}
            </Form.Control.Feedback>
          </Form.Group>
        </>
      );
      break;
    case "textarea-inline":
      inputElement = (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              <b>{props.elementDecorators.label}</b>
              <p style={customStyle.subText}>
                {props.elementDecorators.sublabel}
              </p>
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                {...extraClasses}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}
              />
              <Form.Control.Feedback type={props.elementDecorators.feedback}>
                {props.elementDecorators.feedbackMsg}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </>
      );
      break;
    case "select":
      inputElement = (
        <>
          <Form.Group>
            <Form.Label>
              <b>{props.elementDecorators.label}</b>
            </Form.Label>
            <Form.Control
              as="select"
              {...extraClasses}
              isValid={props.elementConfig.isValid}
              isInvalid={props.elementConfig.isInvalid}
              required={props.elementConfig.required}
              value={props.value}
              onChange={props.changed}
            >
              {props.elementConfig.options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type={props.elementDecorators.feedback}>
              {props.elementDecorators.feedbackMsg}
            </Form.Control.Feedback>
          </Form.Group>
        </>
      );
      break;
    case "select-inline":
      // Deal with deviation from the control in Governance/Research GMS.
      if (props.elementDecorators.placeholders) {
        innerBlockElement = (
          <ul>
            {props.elementDecorators.placeholders.map((placeholder) => (
              <p style={customStyle.subTextWarn}>{placeholder.value}</p>
            ))}
          </ul>
        );
      } else {
        innerBlockElement = <></>;
      }
      inputElement = (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              <b>{props.elementDecorators.label}</b>
              <p style={customStyle.subText}>
                {props.elementDecorators.sublabel}
              </p>
              {innerBlockElement}
              {/*
                            <ul>
                                {props.elementDecorators.placeholders.map(placeholder => (
                                    <p style={customStyle.subTextWarn}>{placeholder.value}</p>
                                ))}
                            </ul>
*/}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                {...extraClasses}
                isValid={props.elementConfig.isValid}
                isInvalid={props.elementConfig.isInvalid}
                required={props.elementConfig.required}
                value={props.value}
                onChange={props.changed}
              >
                {props.elementConfig.options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type={props.elementDecorators.feedback}>
                {props.elementDecorators.feedbackMsg}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </>
      );
      break;
    case "select-inline-reviewer":
      inputElement = (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={7}>
              <b>{props.elementDecorators.label}</b>
              <p style={customStyle.subText}>
                {props.elementDecorators.sublabel}
              </p>
            </Form.Label>
            <Col sm={4}>
              <Form.Control
                as="select"
                {...extraClasses}
                isValid={props.elementConfig.isValid}
                isInvalid={props.elementConfig.isInvalid}
                required={props.elementConfig.required}
                value={props.value}
                onChange={props.changed}
              >
                {props.elementConfig.options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type={props.elementDecorators.feedback}>
                {props.elementDecorators.feedbackMsg}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </>
      );
      break;
    case "checkbox":
      inputElement = (
        <>
          <Form.Group>
            <Form.Check
              label={props.elementDecorators.label}
              {...extraClasses}
              {...props.elementConfig}
              inline={props.formType === "inline"}
            />
            <Form.Control.Feedback
              type={props.elementDecorators.feedback}
            ></Form.Control.Feedback>
          </Form.Group>
        </>
      );
      break;
    case "checkbox-inline":
      inputElement = (
        <>
          <Form.Group as={Row}>
            <Col sm={10}>
              <Form.Check
                label={props.elementDecorators.label}
                {...extraClasses}
                {...props.elementConfig}
                inline={true}
              />
              <Form.Control.Feedback
                type={props.elementDecorators.feedback}
              ></Form.Control.Feedback>
            </Col>
          </Form.Group>
        </>
      );
      break;
    case "file":
      inputElement = (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              <b>{props.elementDecorators.label}</b>
              <p style={customStyle.subText}>
                {props.elementDecorators.sublabel}
              </p>
            </Form.Label>
            <Col sm={8}>
              <Form.Control {...props.elementConfig} onChange={props.changed} />
              <Form.Control.Feedback type={props.elementDecorators.feedback}>
                {props.elementDecorators.feedbackMsg}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </>
      );
      break;
    case "static":
      inputElement = (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              <b>{props.elementDecorators.label}</b>
              <p style={customStyle.subText}>
                {props.elementDecorators.sublabel}
              </p>
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                {...extraClasses}
                value={props.value}
                onChange={props.changed}
                readOnly
              />
              <Form.Control.Feedback type={props.elementDecorators.feedback}>
                {props.elementDecorators.feedbackMsg}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </>
      );
      break;
    default:
      inputElement = (
        <>
          <Form.Group>
            <Form.Label>
              <b>{props.label}</b>
            </Form.Label>
            <Form.Control
              {...extraClasses}
              {...props.elementConfig}
              value={props.value}
              onChange={props.changed}
            />
            <Form.Control.Feedback type={props.elementDecorators.feedback}>
              {props.elementDecorators.feedbackMsg}
            </Form.Control.Feedback>
          </Form.Group>
        </>
      );
  }

  return <>{inputElement}</>;
};

export default input;
