import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import axios from "axios";

const downloadHandler = (submission_id) => {
  const FileDownload = require("js-file-download");

  let filename = submission_id;

  filename += ".pdf";

  let accessToken = localStorage.getItem("access_token");
  if (accessToken) {
    axios({
      url: process.env.REACT_APP_AXIOS_URL + "download/file",
      method: "post",
      auth: {
        username: accessToken,
        password: "unused",
      },
      data: {
        submission_id: submission_id,
        download_type: "review",
      },
      responseType: "arraybuffer",
    })
      .then((received) => {
        console.log(received.data);
        FileDownload(received.data, filename);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          this.messageHandler(
            "expired",
            "Session expired. Please log in again"
          );
        }
      });
  }
};

const ModalElement = (props) => {
  let title_element = <Modal.Title>{props.message.title.value}</Modal.Title>;
  let body_element = null;
  let actionButton = null;

  switch (props.displayMode) {
    case "display":
      if (props.action) {
        actionButton = (
          <Button
            variant="success"
            onClick={() => downloadHandler(props.action.submission_id)}
          >
            {props.action.message}
          </Button>
        );
      }
      body_element = Object.entries(props.message).map(
        ([key, element], idx) => {
          let displayElement = null;
          if (element.type === "textarea") {
            displayElement = (
              <Form.Control
                as="textarea"
                rows="10"
                readOnly
                defaultValue={element.value}
              />
            );
          } else if (element.type === "bullet") {
            let listItem = element.value.map((value, idx) => (
              <ListGroup.Item key={idx}>{value}</ListGroup.Item>
            ));
            displayElement = <ListGroup>{listItem}</ListGroup>;
          } else if (element.type === "file") {
            displayElement =
              element.value === null ? (
                <p>N/A</p>
              ) : (
                <Button variant="primary" onClick={element.value}>
                  Download
                </Button>
              );
          } else if (element.type === "linebreak") {
            displayElement = element.value; //<hr color='red'></hr>
          } else {
            displayElement = (
              <Form.Control readOnly defaultValue={element.value} />
            );
          }

          return (
            <Form.Group as={Row} key={key.toString() + idx.toString()}>
              <Col md={3}>
                <Form.Label column>{element.header}</Form.Label>
              </Col>
              <Col md={9}>{displayElement}</Col>
            </Form.Group>
          );
        }
      );
      break;

    case "action":
      body_element = <p>{props.message.content.value}</p>;
      actionButton = (
        <Button variant="success" onClick={() => props.action.handler}>
          {props.action.message}
        </Button>
      );
      break;

    default:
      body_element = (
        <p>Are you sure you want to perform the following action?</p>
      );
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* Kinda hacky... */}
      <span className="btn-link" onClick={handleShow}>
        {props.message.title.value}
      </span>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <b>{title_element}</b>
        </Modal.Header>
        <Modal.Body>{body_element}</Modal.Body>
        <Modal.Footer>
          {actionButton}
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalElement;
