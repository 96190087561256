import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import ModalElement from "../../../components/UI/Modal/Modal";
import GrantForm from "./GrantForm/GrantForm";

import { customStyle } from "../../../hoc/CustomStyle";
import { SysMapper } from "../../../hoc/FormConfig";

import axios from "axios";

class Grants extends Component {
  state = {
    render: {
      loaded: false,
      content_type: "table", // table or form or submission,
      user_type: null,
    },

    table: {
      content: null,
      header: null,
    },

    form: {
      retrieve_id: null,
      header: null,
      content: null,
    },

    internalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    externalMsg: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  constructor(props) {
    super(props);
    this.parentHandler = this.parentHandler.bind(this);
  }

  renderTable() {
    // load table information
    let accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      axios({
        url: process.env.REACT_APP_AXIOS_URL + "grantcalls/fetch",
        method: "post",
        auth: {
          username: accessToken,
          password: "unused",
        },
        data: {
          request_type: "table",
          retrieve_id: null,
        },
      })
        .then((received) => {
          if (received.status === 200) {
            this.setState({
              ...this.state,
              render: {
                loaded: true,
                content_type: "table",
                user_type: received.data.payload.user_type,
              },
              table: {
                ...this.state.table,
                content: received.data.payload.content,
                header: received.data.payload.header,
              },
            });
          } else {
            this.throwError(1001, "response not 200");
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.messageHandler(
              "expired",
              "Session expired. Please log in again"
            );
          } else {
            this.throwError(1002, "critical error -> " + error.toString());
          }
        });
    } else {
      this.throwError(1003, "missing access token");
    }
  }

  parentHandler(mode) {
    if (mode === "back") {
      this.setState({
        ...this.state,
        render: {
          ...this.state.render,
          content_type: "table",
        },
      });
    } else if (mode === "submitted") {
      this.renderTable();
    }
  }

  throwError(code, reason) {
    var msg = "Error code G" + code.toString() + ":  " + reason;
    this.setState({
      ...this.state,
      internalMsg: {
        triggered: true,
        type: "error",
        content: msg,
      },
    });
  }

  componentDidMount() {
    this.renderTable();
  }

  onClickModifyHandler = (event, id) => {
    event.preventDefault();

    if (!id) {
      // create a new grantcall
      this.setState({
        ...this.state,
        render: {
          ...this.state.render,
          content_type: "form",
        },
        form: {
          retrieve_id: null,
          content: null,
          header: null,
        },
        internalMsg: {
          triggered: true,
          type: "success",
          content: "",
        },
      });
    } else {
      // find the grant information
      let rowContent = null;
      let rowId = null;
      for (var i = 0; i < this.state.table.content.length; i++) {
        if (this.state.table.content[i].grant_id === id) {
          rowContent = this.state.table.content[i];
          rowId = i;
          break;
        }
      }

      // transfer to grant modification
      this.setState({
        ...this.state,
        render: {
          ...this.state.render,
          content_type: "form",
        },
        form: {
          retrieve_id: rowId,
          content: rowContent,
          header: this.state.table.header,
        },
        internalMsg: {
          triggered: true,
          type: "success",
          content: "",
        },
      });
    }
  };

  sortByOrder(obj) {
    const object_array = Object.entries(obj).sort(
      (a, b) => a[1].order - b[1].order
    );
    const output_object = {};

    for (var i = 0; i < object_array.length; i++) {
      output_object[object_array[i][0]] = object_array[i][1];
    }
    return output_object;
  }

  render() {
    let header = null;
    if (this.state.render.user_type === "admin-super")
      header = (
        <>
          <Row>
            <Col sm={10}>
              <b>Grant calls</b>
            </Col>
            <Col sm={2}>
              {/*
              <Button
                size="sm"
                variant="danger"
                onClick={(event) => this.onClickModifyHandler(event)}
              >
                Create
              </Button>
              */}
            </Col>
          </Row>
        </>
      );
    else
      header = (
        <>
          <Row>
            <Col sm={10}>
              <b>Grant calls</b>
            </Col>
          </Row>
        </>
      );
    let content = <p>Loading ...</p>;

    // render message
    let msg = null;
    if (this.state.internalMsg.content.length > 0) {
      if (this.state.internalMsg.type === "success") {
        msg = (
          <p style={customStyle.successMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      } else if (this.state.internalMsg.type === "error") {
        msg = (
          <p style={customStyle.errorMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      }
    }

    // redirect to login page if there is any error
    if (
      this.state.externalMsg.triggered &&
      this.state.externalMsg.type === "error"
    ) {
      localStorage.clear("access_token");
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: {
                type: "error",
                content: this.state.externalMsg.content,
              },
            },
          }}
        />
      );
    }

    // explicit display
    if (this.state.render.loaded) {
      if (this.state.render.content_type === "table") {
        const ordered_headers = this.sortByOrder(this.state.table.header);

        const table_header = Object.entries(ordered_headers).map(
          ([key, entry], idx) => {
            if (entry.display === "table" || entry.display === "both") {
              return <th key={key}>{entry.name}</th>;
            } else {
              return <></>;
            }
          }
        );

        const table_content = this.state.table.content.map((element, idx) => {
          let modal_content = {};
          let action_item = <p>N/A</p>;

          for (const key in ordered_headers) {
            if (
              ordered_headers[key].display === "modal" ||
              ordered_headers[key].display === "both"
            ) {
              modal_content[key] = {
                value:
                  key === "host_pillar"
                    ? SysMapper[element[key]]
                    : element[key],
                type: ordered_headers[key].style,
                header: ordered_headers[key].name,
              };
            }

            if (ordered_headers[key].type === "action") {
              if (element[key] === "admin-super") {
                action_item = (
                  <>
                    <Button
                      size="sm"
                      variant="primary"
                      onClick={(event) =>
                        this.onClickModifyHandler(event, element["grant_id"])
                      }
                    >
                      Modify
                    </Button>
                  </>
                );
              }
            }
          }

          const modalElement = (
            <ModalElement displayMode="display" message={modal_content} />
          );

          const renderedRow = Object.entries(ordered_headers).map(
            ([key, entry], row_idx) => {
              if (entry.display === "table" || entry.display === "both") {
                if (key === "title") {
                  return <td key={row_idx}>{modalElement}</td>;
                } else if (key === "action") {
                  return <td key={row_idx}>{action_item}</td>;
                } else if (key === "host_pillar") {
                  return <td key={row_idx}>{SysMapper[element[key]]}</td>;
                } else {
                  return <td key={row_idx}>{element[key]}</td>;
                }
              } else {
                return <></>;
              }
            }
          );

          return <tr key={idx}>{renderedRow}</tr>;
        });

        content = (
          <Table striped bordered>
            <thead>
              <tr>{table_header}</tr>
            </thead>
            <tbody>{table_content}</tbody>
          </Table>
        );
      } else if (this.state.render.content_type === "form") {
        // Display grantcall form

        if (this.state.form.content) {
          header = (
            <b>Modify grantcall ID: {this.state.form.content.grant_id}</b>
          );
        } else {
          header = <b>Create a new grantcall</b>;
        }
        content = (
          <GrantForm
            grant_infor={this.state.form}
            parentHandler={this.parentHandler}
          />
        );
      }
    }

    return (
      <>
        {msg}
        <div style={customStyle.topBuffer20}>
          <Card>
            <Card.Header>{header}</Card.Header>
            <Card.Body>{content}</Card.Body>
          </Card>
        </div>
      </>
    );
  }
}

export default Grants;
